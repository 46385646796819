import React from 'react';
import NotationStars from '../components/NotationStars';

function FrenchBooksNotation({ t }) {
	const frenchBooks = {
		"Betclic": {
			prematch: 5,
			live: 5
		},
		"WinAMax": {
			prematch: 4.5,
			live: 4
		},
		"PMU": {
			prematch: 4,
			live: 4
		},
		"UniBet": {
			prematch: 4,
			live: -1
		},
		"Parions Sport": {
			prematch: 4,
			live: 2
		},
		"ZeBet": {
			prematch: 3,
			live: -1
		},
		"GenyBet": {
			prematch: 2,
			live: -1
		},
		"OlyBet": {
			prematch: 2,
			live: -1
		},
		"NetBet": {
			prematch: 2,
			live: -1
		},
		"FeelingBet": {
			prematch: 2,
			live: -1
		},
		"Bwin": {
			prematch: 2,
			live: -1
		}
	};

	return (
		<table className="bookmakersList">
			<thead>
				<tr>
					<th>{t("Bookmaker")}</th>
					<th>{t("Volume pré-match")}</th>
					<th>
						<span className="flex">
							{t("Volume")}
							<span className="liveContainer">
								<span className="liveIcon"></span>{t("live")}
							</span>
						</span>
					</th>
				</tr>
			</thead>
			<tbody>
				{
					Object.keys(frenchBooks).map((book, index) => {
						return <tr key={index}>
							<td className="book">{book}</td>
							<td><NotationStars key={`${index}-prematch`} number={frenchBooks[book].prematch} /></td>
							<td><NotationStars key={`${index}-live`} number={frenchBooks[book].live} /></td>
						</tr>
					})
				}
			</tbody>
		</table>
	)
}

export default FrenchBooksNotation
